<template>
  <div>
    <div style="background-color: #eee">
      <div class="nav-top">
        <div>
          <ul class="header">
            <li>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <a href="#">
                    <i class="el-icon-location-outline"></i>{{ location }}
                  </a>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in locationList"
                    :key="item"
                    class="dropdown-item"
                  >
                    {{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li>
              <a href="#">微信客服</a>
            </li>
            <li class="wxgzh-box">
              <a href="#" class="wxgzh" @mouseenter="showgzh = true" @mouseout="showgzh = false">微信公众号</a>
              <img src="@/assets/img/navgatior/wxgzh.jpg" class="gzhimg" v-show="showgzh">
            </li>
          </ul>
        </div>
        <div>
          <ul class="header">
            <li>服务热线：<span class="phone">0769-33300099</span></li>
            <li>加急电话：<span class="phone">158-1996-3100</span></li>
          </ul>
        </div>
        <div>
          <a href="javascript::">登录</a> / 
          <a href="javascript::">注册</a>
        </div>
      </div>
    </div>
    <div style="background-color: #fff">
      <div class="container">
        <div>
          <img src="../../assets/img/qfzx.jpg" class="mark" />
        </div>
        <div class="input-box">
          <el-input placeholder="请输入内容" v-model="keyword" class="input">
            <div slot="append">搜索</div>
          </el-input>
        </div>
      </div>
      <div class="container">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          active-text-color="#ff6600"
        >
          <el-menu-item :index="(index + 1).toString()" v-for="(item,index) in navList" :key="index" v-if="item.cate.length == 0">{{item.title}}</el-menu-item>
          <el-submenu :index="(index + 1).toString()" v-else>
            <template slot="title">{{item.title}}</template>
            <el-menu-item :index="(index + 1).toString() + '-' +(i + 1).toString()" v-for="(cateitem,i) in item.cate" :key="i" class="navitem">{{cateitem}}</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navgatior",
  data() {
    return {
      location: "东莞",
      locationList: ["东莞", "重庆", "深圳", "中山"],
      keyword: "",
      activeIndex: '1',
      navList: [
        {
          title: "首页",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "助企金服",
          url: "/pages/building/building",
          cate:['AI融资','股权融资','项目融资','债券融资','供应融资','证券理财']
        },
        {
          title: "金税数科",
          url: "/pages/tax/tax",
          cate:['公司注册','工商变更','工商注销','银行服务','印章服务','企业报告','财务代理','税务登记','税务咨询','会计服务']
        },
        {
          title: "一知企服",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "金销云",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "企商云",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "护法居士",
          url: "/pages/building/building",
          cate:['法务咨询','法务顾问','司法咨询','合同定制']
        },
        {
          title: "星空文化",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "商学院",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "想念师爷",
          url: "/pages/building/building",
          cate:[]
        },
        {
          title: "中芯汇",
          url: "/pages/building/building",
          cate:[]
        },
      ],
      showgzh:false
    };
  },
};
</script>

<style scoped>
.nav-top {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  padding: 0 10px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #ff6600;
}

.header {
  display: flex;
}

.el-dropdown-link {
  font-size: 16px;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #eee !important;
  color: #ff6600 !important;
}

.phone {
  color: #ff6600;
  font-size: 20px;
  font-weight: 700;
}

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.mark {
  height: 120px;
}

.input-box {
  flex: 2;
}

.input {
  border: 1px solid #ff6600;
}

.input-box >>> .el-input-group__append {
  background-color: #ff6600;
  color: #fff;
  border: 1px solid #ff6600;
  border-radius: 0;
}

.input-box >>> .el-input__inner {
  border-radius: 0;
}

.input-box >>> .el-input__inner:focus {
  border: 1px solid #ff6600;
  border-radius: 0;
}

.container >>> .el-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
}
.container >>> .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 4px solid #ff6600;
  color: #ff6600;
  font-weight: 700;
  transition: all 0.4s ease-in-out;
}

.container >>> .el-menu-item {
  color: #000;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.container >>> .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #ff6600;
  background-color: rgba(255, 102, 0, 0.1);
  border-radius: 20px;
}

.container >>> .el-submenu .el-submenu__title {
  color: #000;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.container >>> .el-submenu .el-submenu__title:not(.is-disabled):hover {
  color: #ff6600;
  background-color: rgba(255, 102, 0, 0.1);
  border-radius: 20px;
  padding: 0 10px;
}

.container >>> .el-submenu__title {
  padding: 0;
}

.container >>> .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 4px solid #ff6600;
  color: #ff6600;
  font-weight: 700;
  transition: all 0.4s ease-in-out;
}

.container >>> .el-submenu__title i {
  color: inherit;
}

.el-menu--horizontal .el-menu .el-menu-item {
  color: #000;
}

.el-menu--horizontal .el-menu .el-menu-item:hover {
  color: #ff6600;
}

.wxgzh-box {
  position: relative;
}

.gzhimg {
  height: 130px;
  width: 130px;
  position: absolute;
  z-index: 1;
  top: 35px;
  left: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .7);
}

</style>