<template>
  <div class="page">
    <div class="container">
      <div class="title">
        <h2>企服中芯顾问</h2>
        <h4>专业 专注 认真 负责</h4>
      </div>
      <div class="content">
        <div class="person" v-for="(item, index) in list" :key="index">
          <el-image
            :src="require('@/assets/img/counselor/' + item.py + '.jpg')"
            fit="cover"
            alt="顾问"
            class="avatar"
            @mouseenter="enter(index)"
            @mouseleave="leave"
          />
          <div
            class="comment"
            :style="[commentStyle(index)]"
            @mouseenter="enter(index)"
            @mouseleave="leave"
          >
            <div class="info">
              <div>{{ item.name }}</div>
              <div>{{ item.grade }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <h2>竭诚为您服务</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "李红朝",
          py: "lihongchao",
          grade: "高级顾问",
        },
        {
          name: "王晓妮",
          py: "wangxiaoni",
          grade: "高级顾问",
        },
        {
          name: "欧丽",
          py: "ouli",
          grade: "高级顾问",
        },
        {
          name: "黄少华",
          py: "huangshaohua",
          grade: "高级顾问",
        },
        {
          name: "石绪澳",
          py: "shixuao",
          grade: "高级顾问",
        },
        {
          name: "王富",
          py: "wangfu",
          grade: "高级顾问",
        },
        {
          name: "孙紫涵",
          py: "sunzihan",
          grade: "高级顾问",
        },
        {
          name: "田森",
          py: "tiansen",
          grade: "高级顾问",
        },
        {
          name: "李笑",
          py: "lixiao",
          grade: "高级顾问",
        },
        {
          name: "王森",
          py: "wangsen",
          grade: "高级顾问",
        },
        {
          name: "周华",
          py: "zhouhua",
          grade: "高级顾问",
        },
        {
          name: "吴静",
          py: "wujing",
          grade: "高级顾问",
        },
        {
          name: "向进",
          py: "xiangjin",
          grade: "高级顾问",
        },
        {
          name: "周晓",
          py: "zhouxiao",
          grade: "高级顾问",
        },
        {
          name: "向文江",
          py: "xiangwenjiang",
          grade: "高级顾问",
        },
        {
          name: "周波",
          py: "zhoubo",
          grade: "高级顾问",
        },
      ],
      isShow: false,
      current: "",
    };
  },
  methods: {
    enter(i) {
      this.isShow = true;
      this.current = i;
      //   console.log("移入");
    },
    leave() {
      this.isShow = false;
      this.current = "";
      //   console.log("移出");
    },
  },
  computed: {
    commentStyle() {
      return (i) => {
        let style = "";
        if (this.isShow && this.current == i) {
          style = {
            height: "100%",
            transition: "0.4s all",
          };
        }
        return style;
      };
    },
  },
};
</script>

<style scoped>
.page {
  background: url("../../assets/img/counselorbg.jpg");
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.title {
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #eee;
  border-radius: 5px;
  padding-top: 4px;
}

.avatar {
  width: 140px;
  height: 180px;
}

.person {
  position: relative;
  margin: 2px 4px;
}

.comment {
  background-color: rgba(0, 0, 0, 0.5);
  width: 140px;
  position: absolute;
  top: 0;
  color: #fff;
  height: 0;
  overflow: hidden;
  transition: 0.4s all;
}

.info {
  text-align: center;
  position: relative;
  top: 30%;
}
</style>