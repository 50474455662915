<template>
  <div>
    <el-carousel :height="bannerHeight + 'px'" class="swiper-box">
      <el-carousel-item v-for="(item, index) in swiperList" :key="index">
        <img
          ref="bannerHeight"
          :src="item.url"
          class="swiperImg"
          @load="imgLoad"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  data() {
    return {
      bannerHeight: "",//轮播图高度
      swiperList:[
        {url:require('../../assets/img/swiper/1.jpg')},
        {url:require('../../assets/img/swiper/2.jpg')},
        {url:require('../../assets/img/swiper/3.jpg')}
      ]
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => {
        this.imgLoad();
      },
      false
    );
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
  },
};
</script>

<style scoped>
.swiper-box >>> .el-carousel__arrow {
  height: 50px;
  width: 50px;
}
.swiper-box >>> .el-icon-arrow-left {
  font-size: 24px;
}
.swiper-box >>> .el-icon-arrow-right {
  font-size: 24px;
}

.swiperImg {
  width: 100%;
}
</style>