<template>
  <div class="box">
    <h4>快速服务通道</h4>
    <h4>
      <div>服务热线</div>
      <div>0769-33300099</div>
    </h4>
    <h4>
      <div>加急电话</div>
      <div>158-1996-3100</div>
    </h4>
    <el-button circle class="check-in-btn" @click="isShow = true"
      >快速通道</el-button
    >

    <!-- 登记框 -->
    <div class="form-box" v-show="isShow">
      <div>
        <h2>快速通道</h2>
        <div class="close-icon" @click="isShow = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <el-form
        ref="form"
        label-position="left"
        label-width="80px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="申请人" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="办理业务" prop="cate">
          <el-input v-model="form.cate"></el-input>
        </el-form-item>
        <el-form-item label="需求" prop="demand">
          <el-input v-model="form.demand"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('form')"
            style="margin-right: 80px"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      form: {
        name: "",
        phone: "",
        cate: "",
        demand: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的名字", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        cate: [
          { required: true, message: "请输入您要办理的业务", trigger: "blur" },
        ],
        demand: [
          { required: true, message: "请输入您的业务需求", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //表单验证提交
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 验证成功要做的事
          console.log("成功");
        } else {
          // 不成功出现显示
          this.$message.error("请按提示填写正确内容");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  background-image: linear-gradient(to bottom, #ff9569 0%, #e92758 100%);
  height: 240px;
  width: 130px;
  position: fixed;
  top: 40%;
  right: 10px;
  z-index: 999;
  border-radius: 0 0 65px 65px;
  text-align: center;
}
h4 {
  margin: 10px 0;
  color: #fff;
}

.check-in-btn {
  width: 80px;
  height: 80px;
  font-weight: 700;
}

.box >>> .el-button:focus,
.el-button:hover {
  color: #ff6600;
}

.form-box {
  background-color: #fff;
  position: fixed;
  top: 30%;
  left: 35%;
  padding: 0 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 500px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 20px;
}
</style>