<template>
  <div>
    <!-- 头部 -->
    <navgatior />
    <!-- 轮播图 -->
    <swiper />
    <!-- 产品楼层 -->
    <floor />
    <!-- 顾问 -->
    <!-- 资讯 -->
    <news />
    <!-- 底部 -->
    <bottom />
    <!-- 登记 -->
    <check-in />
  </div>
</template>

<script>
import navgatior from "../components/home/Navgatior";
import swiper from "../components/home/Swiper";
import floor from "../components/home/Floor";
import counselor from "../components/home/Counselor";
import news from "../components/home/News";
import bottom from "../components/home/Bottom";
import checkIn from '../components/public/CheckIn';
export default {
  name: "Home",
  components: {
    navgatior,
    swiper,
    floor,
    counselor,
    news,
    bottom,
    checkIn
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>
