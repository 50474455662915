<template>
  <div class="bottom">
    <div class="container">
      <div class="bottom-nav">
        <div>
          <ul class="bottom-nav-left">
            <li><a href="Javascript::">首页</a></li>
            <li><a href="Javascript::">找服务</a></li>
            <li><a href="Javascript::">找顾问</a></li>
            <li><a href="Javascript::">找资讯</a></li>
            <li><a href="Javascript::">关于我们</a></li>
          </ul>
        </div>
        <div>官方客服:0769-33300099或158-1996-3100</div>
      </div>
      <div class="address">
          联系地址：广东省东莞市南城街道天安数码城F区F3-503
      </div>
      <div>
          备案号：<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021015982号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bottom {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding-bottom: 20px;
}

.container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}

ul,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.bottom-nav {
  display: flex;
  justify-content: space-between;
  height: 20px;
  padding: 20px;
  border-bottom: 1px solid #666;
}
.bottom-nav-left {
  display: flex;
}

.bottom-nav-left li {
  padding: 0 10px;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color: #ff6600;
}

.address {
    padding: 10px 0;
}
</style>