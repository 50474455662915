<template>
  <div class="container">
    <div class="title">
      <h2>企服资讯</h2>
      <h4>聚焦全行业，深度解析行业实时关注行业动态，最新最全的行业内容为您呈现</h4>
    </div>

    <div class="message">
      <div class="content-left">
        <el-tabs v-model="leftActiveName">
          <el-tab-pane
            :label="item.title"
            :name="index.toString()"
            v-for="(item, index) in messageList"
            :key="index"
          >
            <ul class="info">
              <li v-for="(infoitem, index) in item.list" :key="index">
                <div class="info-item">
                  <div style="width: 60%">
                    <a href="Javascript::">
                      <div class="info-title">{{ infoitem.title }}</div>
                    </a>
                  </div>
                  <div class="info-date">{{ infoitem.date }}</div>
                </div>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="content-right">
        <el-tabs v-model="rightActiveName">
          <el-tab-pane label="企服知识" name="1">
            <div>
              <ul class="knows">
                <li v-for="(item, index) in knowsList" :key="index">
                  <a href="Javascript::">{{ item }}</a>
                </li>
              </ul>
            </div>
            <div>
              <ul class="knows-info">
                <li v-for="(item,index) in knowsInfoList" :key="index">
                  <div class="knows-info-item">
                    <div class="knows-info-item-title">
                      <a href="javascript::"
                        >{{item.title}}</a
                      >
                    </div>
                    <div>{{item.date}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftActiveName: "0",
      rightActiveName: "1",
      messageList: [
        {
          title: "行业动态",
          list: [
            { title: "公司注册规定是什么？", date: "2020-03-05 15:48" },
            { title: "个体户注册需要什么资料？", date: "2020-03-05 15:48" },
            { title: "那些人不能做法人？", date: "2020-03-05 15:48" },
            { title: "如何快速进行专利申请", date: "2020-03-05 15:48" },
            { title: "商标交易流程是什么", date: "2020-03-05 15:48" },
            { title: "注销公司为什么还要罚款", date: "2020-03-05 15:48" },
            { title: "公司注册代理选择这样规避风险", date: "2020-03-05 15:48" },
            { title: "财务代理公司是怎么收费的", date: "2020-03-05 15:48" },
            { title: "公司董事需要负什么法律责任？", date: "2020-03-05 15:48" },
            { title: "教你避免网站制作中开发的错误", date: "2020-03-05 15:48" },
          ],
        },
        {
          title: "公司动态",
          list: [
            { title: "企业网站建设", date: "2020-03-05 15:48" },
            { title: "商标转让", date: "2020-03-05 15:48" },
            { title: "公司商标", date: "2020-03-05 15:48" },
            { title: "个人怎样申请商标", date: "2020-03-05 15:48" },
            { title: "个人物流公司转让的流程和要求有哪些？", date: "2020-03-05 15:48" },
            { title: "抵押贷款能贷多少年，利息怎么计算？", date: "2020-03-05 15:48" },
            { title: "公司转让一般交易价格多少？", date: "2020-03-05 15:48" },
            { title: "商标转让的流程和资料", date: "2020-03-05 15:48" },
            {
              title: "办理网络文化经营许可证续期有哪些条件？",
              date: "2020-03-05 15:48",
            },
            {
              title: "中外合资和中外合作企业的区别是什么？",
              date: "2020-03-05 15:48",
            },
          ],
        },
      ],
      knowsList: [
        "公司注册",
        " 会计代理",
        "商标注册",
        "社保代理",
        "商标交易",
        "融资贷款",
        "法律服务",
        "行业资讯",
      ],
      knowsInfoList:[
            { title: "创业者必知：新成立公司要交哪些税？", date: "2020-03-05 15:48" },
            { title: "五招“万能取名秘籍” 公司核名不再被驳回", date: "2020-03-05 15:48" },
            { title: "代理注册公司选择", date: "2020-03-05 15:48" },
            { title: "关于一般纳税人代理记账的费用问题", date: "2020-03-05 15:48" },
      ]
    };
  },
};
</script>

<style scoped>
.container {
  width: 1200px;
  margin: 0 auto 20px;
}

.title {
  text-align: center;
}

.message {
  display: flex;
}

.content-left {
  flex: 2;
  margin-right: 10px;
  background-color: #fff;
  padding: 10px;
}

.content-right {
  flex: 1;
  background-color: #fff;
  padding: 10px;
}

.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.info li {
  width: 48%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

ul {
  margin: 0;
  padding-left: 20px;
}

.info-item {
  display: flex;
  justify-content: space-between;
}

.info-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.info-date {
  float: right;
  color: #666;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #ff6600;
}

.message >>> .el-tabs__item.is-active {
  color: #ff6600;
}

.message >>> .el-tabs__item:hover {
  color: #ff6600;
}

.message >>> .el-tabs__active-bar {
  background-color: #ff6600;
}
.message >>> .el-tabs__header {
  margin-bottom: 10px;
}

.knows > li {
  list-style: none;
  padding: 2px 10px;
  border: 1px solid #ccc;
  margin: 3px;
}

.knows li:hover {
  border: 1px solid #ff6600;
}

.content-right ul {
  display: flex;
  flex-wrap: wrap;
}

.knows {
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px;
}

.knows-info {
  font-size: 14px;
}

.knows-info-item {
  display: flex;
  justify-content: space-between;
}

.knows-info li {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}

.knows-info-item-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 60%;
}
</style>