var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"floor-box"},_vm._l((_vm.floorList),function(item,index){return _c('div',{key:index,staticClass:"floor-item"},[_c('div',{staticClass:"floor-title",style:({
          borderTop: '2px solid' + item.color,
          borderBottom: '2px solid' + item.color,
          borderLeft: '5px solid' + item.color,
        })},[_c('span',{staticClass:"title-left"},[_vm._v(_vm._s(item.title))]),_vm._m(1,true)]),_c('div',{staticClass:"floor-content"},[_c('div',{staticClass:"cate",style:({
            backgroundImage:
              'url(' +
              require('../../assets/img/floor/' + item.title + '.jpg') +
              ')',
          })}),_c('div',{staticClass:"cate-item-box"},_vm._l((item.cate),function(catei,index){return _c('div',{key:index,staticClass:"cate-item"},[_c('div',[_c('a',{attrs:{"href":"Javascript::"}},[(index == 0)?_c('div',{staticClass:"recommend"}):_vm._e(),_c('div',[_vm._v(_vm._s(catei.title))]),_vm._m(2,true),_c('div',{staticClass:"comment"},[_c('span',[_vm._v(_vm._s(catei.comment))])]),_vm._m(3,true)])])])}),0)])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("企业服务")]),_c('h4',[_vm._v("企服中芯一站式为您提供企业服务")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"title-right"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("More")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"feature"},[_vm._v("精选服务")]),_c('span',{staticClass:"feature"},[_vm._v("快速高效")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail"},[_c('span',[_vm._v("查看详情")]),_c('i',{staticClass:"el-icon-right"})])}]

export { render, staticRenderFns }