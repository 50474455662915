<template>
  <div class="container">
    <div class="title">
      <h2>企业服务</h2>
      <h4>企服中芯一站式为您提供企业服务</h4>
    </div>
    <div class="floor-box">
      <div class="floor-item" v-for="(item, index) in floorList" :key="index">
        <div
          class="floor-title"
          :style="{
            borderTop: '2px solid' + item.color,
            borderBottom: '2px solid' + item.color,
            borderLeft: '5px solid' + item.color,
          }"
        >
          <span class="title-left">{{ item.title }}</span>
          <span class="title-right">
            <a href="javascript:;">More</a>
          </span>
        </div>
        <div class="floor-content">
          <div
            class="cate"
            :style="{
              backgroundImage:
                'url(' +
                require('../../assets/img/floor/' + item.title + '.jpg') +
                ')',
            }"
          ></div>
          <div class="cate-item-box">
            <div
              class="cate-item"
              v-for="(catei, index) in item.cate"
              :key="index"
            >
              <div>
                <a href="Javascript::">
                  <div class="recommend" v-if="index == 0"></div>
                  <div>{{ catei.title }}</div>
                  <div>
                    <span class="feature">精选服务</span>
                    <span class="feature">快速高效</span>
                  </div>
                  <div class="comment">
                    <span>{{catei.comment}}</span>
                  </div>
                  <div class="detail">
                    <span>查看详情</span>
                    <i class="el-icon-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floorList: [
        {
          title: "助企金服",
          comment:
            "企业融资、证券理财、保险、金融学院为一体，整合企业综合信息建立数据模型，以AI大数据智能分析企业综合经营情况，形成企业资信评分，依据大数据智能分析匹配企业合适的金融产品，一键形成企业金融服务方案。减少企业金融服务过程中的人工干预，助力解决企业融资难，减少企业融资成本，线上一键融资，专业、高效精准、简专快。帮助企业实现稳定现金流，选择合适自己的融资产品。帮助银行初步风控，减少不良率，增加成交率。",
          // cate: ["融资", "贷款", "保险", "证券理财"],
          cate: [{
            title:"融资",
            comment:"企服中芯融资服务"
          },{
            title:"贷款",
            comment:"企服中芯贷款服务"
          },{
            title:"保险",
            comment:"企服中芯保险服务"
          },{
            title:"证券理财",
            comment:"企服中芯证券理财服务"
          }],
          color: "#FFB901",
        },
        {
          title: "金税数科",
          comment:
            "基于政府工商税局系统政策致力于成为企业互联网+工商财税行业领跑者，集工商财税智能一站式综合服务解决方案，线上公司核名 起名，工商信息登记 变更，预约银行开户，一键注册公司，刻章，一键报税，一键开立电子发票，线上发票识别，智能凭证，手机APP查账，线上财税咨询顾问，三步获取税筹方案，专业财税专家线上分享 培训，行业论坛资讯接收 交流，智能检测财税风险预警，政务资讯实时同步，CRM管理系统于一体SaaS工商财税服务管理系统，大大缩减财税人员工作量，提高财务的工作效率与财税的管理效率，通过互联网人工智能云计算的技术应用，实现发票、日报表 周报表 月报表 年报表自动生成及实时保存凭证。简单-高效-专业-精准。金税数科让财税专员工作简单一点 效率高一点 专业精准一点，金税数科让企业工商财税规范一点。打造平台90%A级纳税信用企业用户--为企业健康稳定发展保驾护航，打造工商财税从业者最值得信赖工具，建立全国最优企业聚集地。",
          // cate: ["财务会计", "税务服务", "高企服务", "工商年检"],
          cate: [{
            title:"财务会计",
            comment:"企服中芯财务会计服务"
          },{
            title:"税务服务",
            comment:"企服中芯税务服务"
          },{
            title:"高企服务",
            comment:"企服中芯高企服务"
          },{
            title:"工商年检",
            comment:"企服中芯工商年检服务"
          }],
          color: "#FF8752",
        },
        {
          title: "一知企服",
          comment:
            "同步全球各国知识产权治理体系及数据 结合各地资深专家打造线上AI客服1V1服务，智能设计 申报注册 跟进流程 系统风险预警 版权维护等一些列知识产权云服务解决方案。综合知识产权设计 辑稿注册 申请 评估 成果转化 权利保护 知识产权教育 培训 行业实时资讯等一系列一键服务方案，健全国内知产研发保护，推动企业研发成果转化，让知识产权成为企业生产 品牌 营销 盈利不可或缺的一部分，7*24小时全时全维覆盖保护，成为科研企业及从业人员最为可靠的守卫者！",
          // cate: ["专利注册", "版权登记", "知产贯标", "商标交易"],
          cate: [{
            title:"专利注册",
            comment:"企服中芯专利注册服务"
          },{
            title:"版权登记",
            comment:"企服中芯版权登记服务"
          },{
            title:"高企服务",
            comment:"企服中芯知产贯标服务"
          },{
            title:"工商年检",
            comment:"企服中芯商标交易服务"
          }],
          color: "#00BBDE",
        },
        {
          title: "护法居士",
          comment:
            "依据中国法律法规定制线上法务一站通，建立法务数据库模型，随时随地查询法务风险 案件，撰写中国最大最全的法务知识库，随时随地线上资询各种法务问题，智能机器人秒回一切问答，24小时线上智能法务顾问，并邀请全国各类专业律师为企业发展中的维权诉讼，法务风险建立最有力的护城河。",
          // cate: ["法务咨询", "法务顾问", "司法咨询", "合同定制"],
          cate: [{
            title:"法务咨询",
            comment:"企服中芯法务咨询服务"
          },{
            title:"法务顾问",
            comment:"企服中芯法务顾问服务"
          },{
            title:"司法咨询",
            comment:"企服中芯司法咨询服务"
          },{
            title:"合同定制",
            comment:"企服中芯合同定制服务"
          }],
          color: "#8EC979",
        },
        {
          title: "企商云",
          comment:
            "企商云将在大数据 云计算 人工智能 物联网 云服务等技术投入研发 生产，线上9分钟智能定制网站 小程序 APP 内部管理系统 物联网操作系统 定制开发一切软件工程。帮助企业加速现代化升级，实现智能 智慧 智造，助力小微商家低成本高效率解决线上线下线下新零售，打造思域流量，降低人力成本 营销成本。帮助中小微企业解决品牌升级 生产升级 助力新智造全面化。",
          // cate: ["网站建设", "定制小程序", "定制APP", "云服务"],
          cate: [{
            title:"网站建设",
            comment:"企服中芯网站建设服务"
          },{
            title:"定制小程序",
            comment:"企服中芯定制小程序服务"
          },{
            title:"定制APP",
            comment:"企服中芯定制APP服务"
          },{
            title:"云服务",
            comment:"企服中芯云服务"
          }],
          color: "#5881FD",
        },
        {
          title: "金销云",
          comment:
            "依托于互联网平台与技术，解决企业营销难题，搜集全网各行各业各产品成功互联网营销案例，统计分类全网流量平台与各平台形成互通合作，根据企业的营销需求及产品特性系统智能计算总结往日相关产品成功经验，结合当下需求智能生成线上营销方案及预算（投入与产出），联合文化传媒事业部线上指导撰写或拍摄营销内容，一键设置将营销内容按照方案投入比例及时间智能投放至全网平台，减少运营成本，提升企业品牌形象及知名度，增加企业销售额。打造企业低成本高效率高产值的AI冠军业务经理及顶级营销策划顾问。",
          // cate: ["营销活动", "网络推广", "营销预算", "企业形象"],
          cate: [{
            title:"营销活动",
            comment:"企服中芯营销活动服务"
          },{
            title:"网络推广",
            comment:"企服中芯网络推广服务"
          },{
            title:"营销预算",
            comment:"企服中芯营销预算服务"
          },{
            title:"企业形象",
            comment:"企服中芯企业形象服务"
          }],
          color: "#E6BD01",
        },
        {
          title: "星空文化",
          comment:
            "为企业品牌策划，口碑，知名度，曝光量，销售量等商业活动提供全方位策划落地服务，星空文化将主要围绕图文出传播 视频传播IP塑造推出如下服务：摄影拍摄 文案编稿设计 需求定制人物带货推广 平台代运营 教育孵化。",
          // cate: ["公众号运营", "活动策划", "文案设计", "摄影拍摄"],
          cate: [{
            title:"公众号运营",
            comment:"企服中芯公众号运营服务"
          },{
            title:"活动策划",
            comment:"企服中芯活动策划服务"
          },{
            title:"文案设计",
            comment:"企服中芯文案设计服务"
          },{
            title:"摄影拍摄",
            comment:"企服中芯摄影拍摄服务"
          }],
          color: "#FF8091",
        },
        {
          title: "想念师爷",
          comment:
            "聚集国内外行业顶级大咖，用实战成功经验辅导企业股权设计 股权激励 上市辅导 战略部署 市值管理 政企联合等一系列工作做师爷式角色，针对具备一定规模的企业打造可持续健康发展的组织架构与营销策略及战略部署，打造中国式百年企业，成为中国式麦肯锡。",
          // cate: ["上市辅导", "战略部署", "市值管理", "政企联合"],
          cate: [{
            title:"上市辅导",
            comment:"企服中芯上市辅导服务"
          },{
            title:"战略部署",
            comment:"企服中芯战略部署服务"
          },{
            title:"市值管理",
            comment:"企服中芯市值管理服务"
          },{
            title:"政企联合",
            comment:"企服中芯政企联合服务"
          }],
          color: "#977EF6",
        },
        {
          title: "商学院",
          comment:
            "分为经商院与专研院，为企业主及专业人才提供相关教学工作，致力于成为国内优秀的企业家输出地与专业人才提升地，为企业时代转型 健康持续发展培养定制式接班人，为企业发展培养优秀的领域专业人才。",
          // cate: ["演讲", "学习", "培养", "研究"],
          cate: [{
            title:"上市辅导",
            comment:"企服中芯演讲服务"
          },{
            title:"战略部署",
            comment:"企服中芯学习服务"
          },{
            title:"市值管理",
            comment:"企服中芯培养服务"
          },{
            title:"政企联合",
            comment:"企服中芯研究服务"
          }],
          color: "#01B9C1",
        },
        {
          title: "中芯汇",
          comment:
            "汇集国内外优秀的商业圈层，实现商业互联 利益共享 患难共当 发展共议，为商会协会打造内部沟通管理系统，让商会利益最大化，服务最优化。为商协会增员扩队提供衔接推广服务，为商协会会员与会员之间的商业价值提现打造一站通品台。",
          // cate: ["商业圈", "商会", "学院", "商业服务"],
          cate: [{
            title:"商业圈",
            comment:"企服中芯商业圈服务"
          },{
            title:"商会",
            comment:"企服中芯商会服务"
          },{
            title:"学院",
            comment:"企服中芯学院服务"
          },{
            title:"商业服务",
            comment:"企服中芯商业服务服务"
          }],
          color: "#149DFB",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  width: 1200px;
  margin: 20px auto;
}

.title {
  text-align: center;
}
.floor-box {
  display: flex;
  flex-wrap: wrap;
}

.floor-item {
  width: 48%;
  padding: 10px;
  margin-bottom: 20px;
}
.floor-title {
  height: 30px;
  line-height: 30px;
  border-radius: 20px 0 0 0;
}

.title-left {
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px;
}

.title-right {
  float: right;
  color: #666;
  font-size: 12px;
  margin-right: 10px;
}

.title-right a:hover {
  color: #ff6600;
}

.floor-content {
  display: flex;
  height: 390px;
}
.cate {
  color: #fff;
  background-repeat: no-repeat;
  background-size: 180px 390px;
  min-width: 180px;
}

.cate-comment {
  font-size: 14px;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}

.cate-item-box {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  background-color: #fff;
}

.cate-item {
  width: 49.8%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  position: relative;
  line-height: 30px;
}
.cate-item:hover a {
  color: #ff6600;
}

.cate-item:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.cate-item:hover .feature {
  color: #ff6600;
  border: 1px solid #ff6600;
}
.cate-item:hover .comment {
  color: #ff6600;
}
.cate-item:hover .detail {
  color: #ff6600;
}

.cate-item-box .cate-item:nth-child(odd) {
  border-right: 1px solid #ddd;
}

.recommend {
  background: url('../../assets/img/tag/recommend.png') no-repeat 0/100% 100%;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  right: 0;
}

a {
  color: #000;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.feature {
  border: 1px solid #ccc;
  font-size: 12px;
  color: #666;
  padding: 1px 6px;
  margin: 3px;
}

.comment,.detail {
  font-size: 12px;
  color: #666;
}
</style>