<template>
  <div id="app">
    <el-backtop></el-backtop>
    <home></home>
  </div>
</template>
<script>
import home from './views/Home'

export default {
  components:{
    home
  }
}
</script>


<style>
  body {
    padding: 0;
    margin: 0;
    background-color: #eee;
  }
</style>
